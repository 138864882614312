import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22a61ffc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-home" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_view = _resolveComponent("page-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_view, null, {
      default: _withCtx(() => [
        (_ctx.projects)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.projects, (project) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "v-home__project",
                onClick: ($event: any) => (_ctx.openProject(project))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.cover, (coverData) => {
                  return (_openBlock(), _createElementBlock("img", {
                    class: "v-home__project__cover",
                    src: coverData.url,
                    alt: ""
                  }, null, 8, _hoisted_3))
                }), 256)),
                _createTextVNode(" " + _toDisplayString(project.title), 1)
              ], 8, _hoisted_2))
            }), 256))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}