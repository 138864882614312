import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-465240e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "v-resources-item__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "v-resources-item__title" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "v-resources-item",
    href: _ctx.data.link,
    target: "_blank"
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.images, (image) => {
        return (_openBlock(), _createElementBlock("img", {
          src: image.url
        }, null, 8, _hoisted_3))
      }), 256))
    ]),
    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.data.title), 1),
    _createElementVNode("div", {
      class: "v-resources-item__content",
      innerHTML: _ctx.data['description en']
    }, null, 8, _hoisted_5)
  ], 8, _hoisted_1))
}