
import {defineComponent, PropType} from 'vue';
import {IResourcesItem} from "@/store"

export default defineComponent({
  name: 'ResourcesItem',

  props: {
    data: {
      required: true,
      type: Object as PropType<IResourcesItem>,
    },
  },

  components: {
  },
});
