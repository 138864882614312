
import { defineComponent } from 'vue';
import ResourcesItem from "@/components/ResourcesItem.vue"
import {IProjects, IProject, storeKey} from "@/store"
import PageView from "@/components/PageView.vue"
import {useStore} from "vuex"
import {apiProjectsPathUri} from "@/main"

export default defineComponent({
  name: 'Home',
  components: {
    PageView,
    ResourcesItem
  },

  data() {
    return {
      store: useStore(storeKey),
    }
  },

  computed: {
    projects(): IProjects | null {
      return this.store.state.projectsList
    }
  },

  methods: {
      openProject(project: IProject) {
        const projectApiUriMinified = project.apiUri.replace(apiProjectsPathUri, '')

        this.$router.push({
          name: 'Project',
          params: {
            apiProjectUriMinified: projectApiUriMinified
          }
        })
      },
  },

  mounted() {
  }

});
